<template>
  <div>
    <div class="wrapper">
      <router-view />
    </div>
    <footer class="">
      <b-card class="">
        <div class="row">
          <div class="col-lg-6">
            <ul class="list-inline mb-0">
              <li class="list-inline-item mx-2">
                <span
                  style="cursor: pointer"
                  class="text-primary"
                  @click="
                    $router.push({
                      name: 'general.privacy-policies',
                    })
                  "
                  >{{ $t("app.privacy-policy") }}</span
                >
              </li>
            </ul>
          </div>
          <div class="col-lg-6 text-right">
            {{ new Date().getFullYear() }}
            <span>&copy; {{ getNameApp }}</span>
          </div>
        </div>
      </b-card>
    </footer>
  </div>
</template>
<script>
import { APPNAME, core } from "../config/pluginInit";

export default {
  name: "BlankLayout",
  mounted() {
    core.mainIndex();
  },
  computed: {
    getNameApp() {
      return this.$route.query.college ?? APPNAME;
    },
  },
};
</script>
<style>
@import url("../assets/css/custom.css");
.modal-backdrop {
  opacity: 0.5;
}
</style>
